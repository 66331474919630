<template>
    <div>
        <a-sidebar></a-sidebar>
        <div class="main-content" id="panel">
            <a-mobile-sidebar></a-mobile-sidebar>
            <a-header title="" class="bg-light text-white">
                <div class="col-lg-7 col-md-10">
                    <h1 class="display-2 text-dark text-capitalize">Production Utilities</h1>
                    <p class="text-dark mt-0 mb-5">Record daily resource usages and utilities for production batch</p>
                </div>
            </a-header>
            <a-content>
                <div class="col-md-7">                    
                    <resource-usage-report v-if="showUtilitiesReport" :batchId="batch_id" :batchName="batch_name"/>
                    <form-card card-title="Select Production Batch" card-sub-title="See Utilities for Batch" v-else>
                        <form v-on:submit.prevent="submitForm">
                            <div class="form-group mb-3">
                                <div>
                                    <label>Select Batch</label>
                                    <select class="form-control" v-model="form.batch_id" required>
                                        <option v-for="option in batches" :value="option.batch_id" :key="option.batch_id">{{option.batch_name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="text-center">
                                <button type="submit" class="btn btn-primary my-4"><i class="ni ni-check-bold"></i> Show Utilities</button>
                            </div>
                        </form>
                    </form-card>
                </div>
                <div class="col-md-5">
                    <resource-usage-form v-if="showUtilitiesCreateForm" :batchId="form.batch_id" @success="reloadComponent"/>
                </div>
                <div class="col-12 text-center mb-4">
                    <button v-if="showCloseBtn" type="button" @click="close" class="btn text-pink btn-sm mb-4"><i class="fas fa-window-close"></i> close</button>
                </div>
            </a-content>
            <a-footer></a-footer>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";
import AdminContentVue from '../../components/Admin/AdminContent.vue'
import AdminFooterVue from '../../components/Admin/AdminFooter.vue'
import AdminHeaderVue from '../../components/Admin/AdminHeader.vue'
import AdminMobileNavVue from '../../components/Nav/AdminMobileNav.vue'
import AdminSideBarVue from '../../components/Nav/AdminSideBar.vue'
import NoButtonWhiteCardVue from '../../components/Cards/NoButtonWhiteCard.vue';
import ResourceUsageFormVue from './Production/ResourceUsageForm.vue';
import ResourceUsageReportsVue from './Production/ResourceUsageReports.vue';
export default {
    name: 'AdminUtilities',
    components: {
        'a-header': AdminHeaderVue,
        'a-mobile-sidebar': AdminMobileNavVue,
        'a-sidebar': AdminSideBarVue,
        'a-content': AdminContentVue,
        'a-footer': AdminFooterVue,
        'form-card': NoButtonWhiteCardVue,
        'resource-usage-form':ResourceUsageFormVue,
        'resource-usage-report': ResourceUsageReportsVue
    },
    data() {
        return{
            batches: null,
            batch_id: null,
            batch_name: null,
            showUtilitiesReport: false,
            showUtilitiesCreateForm: false,
            showCloseBtn: false,
            form:{
                batch_id: ''
            }
        }
    },
    computed : {
        ...mapState({
            authToken : state => state.authToken
        })
    },
    mounted(){
        if (this.authToken) {
            this.fetchBatches()
        }
        else{
            this.$router.push('/login')
        }
    },
    methods:{
        fetchBatches(){
            axios.get(process.env.VUE_APP_BASEURL+'/admin/production/batches',{
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken 
                }
            })
            .then(response => {
                this.batches = response.data.data
            })
            .catch(error => {
                this.$moshaToast(error.response.data.message,{
                    type: 'danger'
                });
            })
        },
        getBatch(){
            axios.get(process.env.VUE_APP_BASEURL+'/admin/production/batches/'+this.form.batch_id,{
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken 
                }
            })
            .then(response => {
                this.batch_name = response.data.data.batch_name
            })
        },

        submitForm(){
            this.getBatch(),
            this.batch_id = this.form.batch_id,
            this.showUtilitiesReport = true,
            this.showUtilitiesCreateForm = true,
            this.showCloseBtn = true
        },
        close(){
            this.showUtilitiesReport = false,
            this.showUtilitiesCreateForm = false,
            this.showCloseBtn = false         
        },
        reloadComponent(){
            this.showUtilitiesReport = false;
            this.$nextTick(() => {
                this.showUtilitiesReport = true;
            });
        }
    },
}
</script>